import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
// import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#E80435';
const footerRelatedLinks = [
	{
		title: 'HESAV',
		url: '/en/projects/hesav-haute-ecole-vaudoise-sante/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/debiopharm/debio_hero.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/debiopharm/debio_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/debiopharm/debio_1.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/debiopharm/debio_2.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_3: file(relativePath: { eq: "projects/mjf/mjf_3.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_4: file(relativePath: { eq: "projects/mjf/mjf_4.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_5: file(relativePath: { eq: "projects/mjf/mjf_5.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_6: file(relativePath: { eq: "projects/mjf/mjf_6.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_7: file(relativePath: { eq: "projects/mjf/mjf_7.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1270, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_8: file(relativePath: { eq: "projects/mjf/mjf_8.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_9: file(relativePath: { eq: "projects/mjf/mjf_9.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 800, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjf_10: file(relativePath: { eq: "projects/mjf/mjf_10.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[{ uri: '/fr/projets/debiopharm/', lang: 'fr' }]}
			title="Debiopharm - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Debiopharm"
				// TODO: replace with this videoCode e9711ce590c51e1fc6a807beb8a7a56c
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Develop the best treatments by focusing on the real
							needs of patients.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Strategy',
								'Creative content',
								'Visual design',
								'Website',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Who</h3>
						<p>
							For more than 40 years, Debiopharm has dedicated
							itself to the development of new drugs as they
							tirelessly search for better treatments for
							patients. The group's strategic evolution made them
							rethink the way they present themselves. So they
							called in superhuit to revitalize their brand image
							and completely redesign their digital presence.{' '}
						</p>
						<h3>What</h3>
						<p>
							The project team restructured the information to
							make it easier to understand who the people behind
							Debiopharm are and what they do. We updated the
							brand to focus on the human being by putting them at
							the forefront of everything. The artistic direction
							led us to a refined and sober graphic expression.
						</p>
						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.debiopharm.com/"
								text="Visit the website"
							/>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="45ed6ca3eef7b6988062436c114572a9"
					alt=""
				/>
				<Column>
					<div className="text-content column__sticky column__text">
						<h3>
							A visual rhythm that balances between dynamism and
							tranquility
						</h3>
						<p>
							The brand uses a bright red as a nod to its Swiss
							and Japanese heritage. Its meticulous integration
							across all the interfaces brings both structure and
							dynamism.{' '}
						</p>
					</div>
					<div className="text-content column__media">
						<Image {...data.image_1.childImageSharp} alt="" />
						<Image {...data.image_2.childImageSharp} alt="" />
					</div>
				</Column>

				<Video
					className="col-full"
					src="62b52b63acb499eb60a228581bdec114"
					alt=""
				/>

				{/*
				<Blockquote
					modifiers="big"
					uptitle="Client Testimony"
					quote="superhuit were a true strategic partner for the development of a new digital strategy for Montreux Jazz Festival and its business universe."
					more="They provided fun and engaging workshops, 1:1 sessions to devise and simplify a complex multichannel, digital portfolio. This has resulted in a beautiful, progressive website experiences that can grow with the needs of a new media business. It is a very committed, passionate partnership based on trust, expertise with creatively at the heart of everything we do together."
					cite="Michelle Goff, Digital Specialist &amp; Project Lead"
				/> */}

				<Column modifiers="left">
					<div className="column__media text-content">
						<Video src="7bfcc789a90b9c64fc32886f5e50b4a1" alt="" />
					</div>

					<div className="column__text column__sticky text-content">
						<h3>
							An information structure designed for all contexts
						</h3>
						<p>
							Each media context was carefully considered in order
							to provide a fluid experience for the user, whether
							they are on a cell phone, tablet or computer.
						</p>
					</div>
				</Column>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
